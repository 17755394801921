<template>
	<h1><i class="bi bi-slash-circle"></i> NOT FOUND</h1>
    <p class="mt-3">ページが見つかりません</p>
</template>

<script>
export default {
    name: 'PageError404',
}
</script>

<style scoped>

</style>
